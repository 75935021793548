import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import LabelField from "../../Component/labelField";
import CustomDropdown from "../../Component/customDropdown";
import { Pagination } from "@mui/material";
import {
  getExamList,
  getExamStreamList,
} from "../../Redux/Actions/counsellorPanelAction";
import PageTitle from "../../Component/pageTitle";
import PaginationTable from "../../Component/paginationTable";
import PaginationView from "../../Component/paginationView";
import usePagination from "../../Component/usePagination";
import { getTableDataSlots } from "../../DATA/dataConstant";
import SubmitButton from "../../Component/submitButton";
import DatePicker from "../../Component/datePicker";
import { getResulCompilation } from "../../Redux/Actions/bdeAdminPanelAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import InputField from "../../Component/inputField";
import ImagePickerAndViewer from "../../Component/imagePickerAndViewer";
import moment from "moment";
import { Link } from "react-router-dom";

export default function StudentResult() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [startDate, SetStartDate] = useState<any>("");
  const [endDate, SetEndDate] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examStream, setExamStream] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [examStreamList, setExamStreamList] = useState([]);
  const [resulCompilation, setResulCompilation] = useState<any>([]);
  const [qualifyFor, setQualifyFor] = useState<any>("");
  const [studentDetailData, setStudentDetailData] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [tempState, setTempState] = useState<any>("");

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(count, rowsPerPage);

  useEffect(() => {
    dispatch(
      getExamList((res: any) => {
        if (res?.data?.length > 0) {
          setExamList(res?.data);
        } else {
          setExamList([]);
        }
      })
    );
  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
        examTypeId: exam?._id,
        baseCourseId: examStream?._id,
      };
    }
    dispatch(
      getResulCompilation(
        data,
        (res: any) => {
          setCount(res?.count);
          setResulCompilation(res?.results);
        },
        () => {}
      )
    );
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onChangeExam = (val: any) => {
    setExam(val);

    dispatch(
      getExamStreamList(`${val._id}`, (res: any) => {
        setExamStreamList(res);
      })
    );
  };
  const onChangeExamStream = (val: string) => {
    setExamStream(val);
  };
  const onChangeQualifyFor = (val: string) => {
    setQualifyFor(val);
  };

  const handleNavigate = (data: any) => {
    // navigate("../collegeDetails", {
    //   state: {
    //     leadData: data?._id,
    //   },
    // });
    console.log("the data is", data?.comment);
    setStudentDetailData(data);
    setOpen(true);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const postData = {
      startDate: startDate,
      endDate: endDate,
      examTypeId: exam?._id,
      baseCourseId: examStream?._id,
      skip: 0,
      limit: rowsPerPage,
      // qualifyYear: qualifyYear?.name,
      // qualifyFor: qualifyFor?.name,
    };
    dispatch(
      getResulCompilation(
        postData,
        (res: any) => {
          setCount(res?.count);
          setResulCompilation(res?.results);
        },
        () => {}
      )
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderSearchMode = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="d-flex">
          <div className="col-md-4 d-flex my-3 ">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Start Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => SetStartDate(value)}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex my-3 ms-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="End Date:" />
            </div>
            <div className="col-md-9">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => SetEndDate(value)}
              />
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="col-md-4 d-flex mb-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Name" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam"
                setInputText={(value: any) => onChangeExam(value)}
                value={exam}
                options={examList}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex mb-3 ms-3">
            <div className="col-md-3 mt-2">
              <LabelField lableName="Exam Stream" />
            </div>
            <div className="col-md-9 ms-2 pe-2">
              <CustomDropdown
                lableName="Select Exam Stream"
                setInputText={(value: any) => onChangeExamStream(value)}
                value={examStream}
                options={examStreamList}
              />
            </div>
          </div>
          <div className=" d-flex mb-3 ms-5">
            <SubmitButton name={"Submit"} />
          </div>
        </div>
        {/* <div className="d-flex">
            <div className="col-md-4 d-flex mb-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Qualify Year" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Qualify Year"
                  setInputText={(value: any) => setQualifyYear(value)}
                  value={qualifyYear}
                  options={yearData}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-3 ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Qualified for" />
              </div>
              <div className="col-md-9 ms-2 pe-2">
                <CustomDropdown
                  lableName="Select Qualified for"
                  setInputText={(value: any) => onChangeQualifyFor(value)}
                  value={qualifyFor}
                  options={ResultOtherDetails}
                />
              </div>
            </div>
            
          </div> */}
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex justify-content-end mt-0">
          {/* <div className="mt-3">
            {resulCompilation?.length !== 0 &&
            resulCompilation?.data?.length !== 0 ? (
              <div className="d-flex mb-2 align-items-center">
                <DownloadTableExcel
                  filename="student_count"
                  sheet="Lead Count"
                  currentTableRef={tableRef.current}
                >
                  <button className="btn btn-outline-primary mb-3 submitbtn py-1">
                    Export Details
                  </button>
                </DownloadTableExcel>
              </div>
            ) : null}
          </div> */}
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        </div>

        <PaginationView
          pagename={"showResult"}
          count={count}
          tableData={resulCompilation ? resulCompilation : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />

        <PaginationTable
          tableData={resulCompilation ? resulCompilation : []}
          pagename={"showResult"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Student Name",
            "Exam Name",
            "Exam Stream",
            "Qualification",
            "Qualified Year",
            "Added date",
            "Actions",
          ]}
          handleNavigate={(value: any) => handleNavigate(value)}
        />
        <div ref={tableRef} className="d-none">
          <PaginationTable
            tableData={resulCompilation ? resulCompilation : []}
            pagename={"showResultExport"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "Student Name",
              "Exam Name",
              "Exam Stream",
              "Qualification",
              "Qualified Year",
              "Added date",
              "Rank",
              "Roll Number",
            ]}
          />
        </div>
      </>
    );
  };

  return (
    <div className="contentBox ">
      <PageTitle name={"STUDENT RESULT"} />
      <div className="formDiv col-12 p-2">
        {renderSearchMode()}
        {renderListMode()}
      </div>
      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="model"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="resultModelDiv p-3">
              <div className="mb-0 d-flex justify-content-between ">
                <p className="fw-bold">
                  <span style={{ color: "#3F51B5" }}> Student Details</span>
                </p>
                <ClearOutlinedIcon
                  className="colorPurple cursor"
                  onClick={handleClose}
                />
              </div>
              <div className="form-check">
                <form>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Student Name" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Student Name"
                        value={`${studentDetailData?.firstName} ${studentDetailData?.lastName}`}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Exam Name" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Exam Name"
                        value={studentDetailData?.examTypes?.name}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Exam Stream Name" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Exam Stream Name"
                        value={studentDetailData?.basecourses?.name}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex mb-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName={"Image"} />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <ImagePickerAndViewer
                        lableName={""}
                        value={studentDetailData?.imageLink}
                        disabled={true}
                        handleChange={(e: any) => setTempState(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Qualification" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Qualification"
                        value={studentDetailData?.qualifyFor}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Qualified Year" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Qualified Year"
                        value={studentDetailData?.qualifyYear}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Mobile Number" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Mobile Number"
                        value={studentDetailData?.mobileNo}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Rank" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Rank"
                        value={studentDetailData?.rank}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Roll Number" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Roll Number"
                        value={studentDetailData?.rollNumber}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex  mt-0">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName="Added At" />
                    </div>
                    <div className="col-md-9 ms-2 pe-2">
                      <InputField
                        placeholder="Enter Added At"
                        value={moment(studentDetailData?.createdAt)
                          .utc()
                          .format("DD-MM-YYYY")}
                        disabled={true}
                        onChangeInput={(value: any) => setTempState(value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mb-3 d-flex">
                    <div className="col-md-3 mt-2">
                      <LabelField lableName={"View PDF"} />
                    </div>

                    <div className="col-md-9 ms-2 pe-2">
                      <Link to={studentDetailData?.scoreCard} target="_blank">
                        <p>View PDF</p>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : null}
    </div>
  );
}
